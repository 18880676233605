import React, { Component } from 'react'

import SEO from '../components/seo'

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import { theme } from '../util/style'

import PageCtaBoxes from '../components/page-cta-boxes'

import Title from '../components/page-title'
import Copy from '../components/page-copy'

class Page extends Component {
  render() {
    return (
      <>
        <SEO title="Thanks" />
        <Wrap>
          <Flex>
            <Box width={1} px={2}>
              <FormWrap>
                <CopyBox>
                  <Title align="center">
                    Excellent, thank you for your request.
                  </Title>
                  <Copy align="center">
                    <p>
                      You will receive an email shortly where you can download
                      your guide. In the meantime, take the time to learn more
                      about Happy Haus.
                    </p>
                  </Copy>
                </CopyBox>
              </FormWrap>
            </Box>
          </Flex>
        </Wrap>
        <PageCtaBoxes />
      </>
    )
  }
}

export default Page

const Wrap = styled.div`
  margin-top: 85px;
  margin-bottom: 85px;
`

const FormWrap = styled.div`
  background: ${theme.colors.grey4};
  padding: 100px 200px 0px 200px;
  @media (max-width: 800px) {
    padding: 20px;
  }
`

/*
const FormBox = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  margin: 0 auto;
`
*/

const CopyBox = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
`
/*
const FormTitle = styled.div`
  padding: 50px 0;
  font-size: 180%;
  color: ${theme.colors.bronze};
  text-align: center;
`
*/
